.hfc-sidebar-menu {
  padding: 10px 0;

  .p-panelmenu-panel {
    padding: 6px 0;
  }

  .menu-item {
    padding: 8px !important;
    font-size: 13.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(255, 255, 255) !important;
    cursor: pointer;
  }

  .p-submenu-list .p-menuitem {
    padding-top: 8px;
  }

  .menu:hover,
  .menu-item:hover {
    background: $primary;
    color: #fff !important;
  }

  .menu-item:hover .menu-icon {
    color: #fff !important;
  }

  .menu-item svg {
    width: 16px;
    transition: all linear 0.3s;
    margin-right: 12px;
  }

  .p-highlight svg {
    transform: rotate(-180deg);
  }

  .menu-title-wrapper {
    display: flex;
    align-items: center;
  }

  .menu-icon {
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 5px 0 0;
    vertical-align: middle;
    color: #FFF;
    font-size: 18px;
    width: 25px;
    height: 20px;
  }

  .p-panelmenu-content {
    padding: 0;
    border: 0;
    background: unset;
  }

  .p-submenu-list li .menu {
    padding-left: 10px;
  }

}