.report-csv-button-hidden {
  visibility: hidden;
}

.report-form-button-group {
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0;
  width: 100%;
}

.report-download-button-group {
  text-align: right;
  padding: 1rem;
}

.report-title,
.card-title-section {
  font-size: 20px;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.csv-pagination-download {
  .p-link {
    margin: 5px 5px;
    border-radius: 5px;
  }

  .page-link {
    background: #2196F3;
    border-color: #4caf50 !important;
    color: #fff !important;
    margin: 0 5px !important;
    border-radius: 5px !important;
    position: relative;
    padding: 3px;
    border: 0px !important;
  }

  .cityone-fd-num {
    position: absolute;
    top: 16px;
    right: -4px;
    height: 8px;
    width: 8px;
    background: #2a882ded;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 10px;
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    background: #4caf50
  }
}